import React, { Suspense, useEffect } from "react";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./ScrollToTop";
import { APP_CONST } from "./config/const";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAppDetails } from "./redux/app/actions";
import LoaderImg from "./assets/images/loader.gif";
import { APP_ROUTES } from "./config/appRoutes";
import { RouteGuard } from "./AppLayout";

const App = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails, organisationStatus } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = appDetails?.SEO_TITLE || "";
    document.canonical = appDetails?.LOGO_URL;

    if (
      organisationStatus !== null &&
      organisationStatus !== APP_CONST.ORGANISATION_STATUS.Live &&
      window.location.pathname.indexOf("maintenance") < 0
    ) {
      window.location.href = "/maintenance";
    }
  }, [appDetails]);

  const setVhUnit = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    dispatch(getAppDetails());
    // Initialize on mount
    setVhUnit();
    // Add event listener for resize
    window.addEventListener("resize", setVhUnit);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", setVhUnit);
    };
  }, []); // Empty dependency array ensures that this effect runs once on mount

  return (
    <Router>
      <div
        dangerouslySetInnerHTML={{
          __html: `<script type="application/ld+json">
{
	"@context": "http://schema.org/",
	"@type": "WebSite",
	"url": " https://www.daddy365.com ",
	"potentialAction": {
		"@type": "SearchAction",
		"target": "{search_term_string}",
		"query-input": "required name=search_term_string"
	}
}
</script>`,
        }}
      ></div>
      <ToastContainer
        closeButton={false}
        position="top-right"
        autoClose={1000}
      />
      <ScrollToTop />
      <Suspense
        fallback={
          <div className="LoaderImg">
            <img src={LoaderImg} />
          </div>
        }
      >
        <Routes>
          {APP_ROUTES.map((route, idx) => {
            return (
              <Route
                path={route.path}
                key={idx}
                element={
                  <RouteGuard
                    routeType={route.routeType}
                    isAuth={isAuth}
                    {...route?.props}
                  >
                    <route.component isAuth={isAuth} />
                  </RouteGuard>
                }
              />
            );
          })}

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
