import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Offcanvas,
  Modal,
  Nav,
  Navbar,
  Dropdown,
} from "react-bootstrap";
import { logoutUser } from "../redux/auth/actions";
import { CasinoProviders } from "../lib/data";
import { getUserBet } from "../redux/sports/actions";
import ToggleIcon from "../assets/images/icons/toggle-icon.png";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import numberWithCommas from "../utils/numbersWithComma";
import Profile from "../assets/images/icons/user.webp";
import MyBets from "../assets/images/icons/my-bets-wht.png";
import Bonus from "../assets/images/icons/bonus-wht.png";
import Referral from "../assets/images/icons/referral-wht.png";
import Statement from "../assets/images/icons/statement-wht.png";
import SPMGames from "../assets/images/icons/spm-games.png";
import Logout from "../assets/images/icons/signout-wht.png";
import Sports from "../assets/images/games-icon/headicon/sports.png";
import Exchange from "../assets/images/games-icon/headicon/exchange.png";
import Sportsbook from "../assets/images/games-icon/headicon/sportsbook.png";
import Evolution from "../assets/images/games-icon/headicon/evolution.png";
import Ezugi from "../assets/images/games-icon/headicon/ezugi.png";
import Slots from "../assets/images/games-icon/headicon/slots.png";
import Kingmaker from "../assets/images/games-icon/headicon/kingmaker.png";
import Aviator from "../assets/images/games-icon/Aviator.png";
import GamesSearchModal from "../views/components/GamesSearchModal";
import { getNotifications, handleLeftSideBar } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";
import { VscVerifiedFilled } from "react-icons/vsc";

import CasinoICon from "../assets/images/icons/spades.png";
import SportsICon from "../assets/images/icons/football.png";
import SlotsIcon from "../assets/images/icons/slot-machine.png";

import LogoutIcon from "../assets/images/icons/logout.webp";
import BonusIcon from "../assets/images/icons/Bonus.webp";
import MybetsIcon from "../assets/images/icons/MyBets.webp";
import ProfileIcon from "../assets/images/icons/profile.webp";
import DepositIcon from "../assets/images/icons/Deposit.webp";
import WithdrawIcon from "../assets/images/icons/withdrawal.webp";
import AccountStatememtIcon from "../assets/images/icons/cashier.webp";
import ReferralIcon from "../assets/images/icons/Refferal.webp";
import SportsICon2 from "../assets/images/icons/trophy.webp";

const HeaderAfterLogin = () => {
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const htmlElement = document.documentElement;
  const { showLeftSideBar } = useSelector((state) => state.app);

  useEffect(() => {
    loadNotifications();
    return () => {};
  }, []);

  useEffect(() => {
    if (showLeftSideBar) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbat-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftSideBar]);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const handleToggleClick = () => {
    const realTimeValue = !showLeftbarToggle;
    if (realTimeValue) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbat-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbat-opened");
    }
    setShowLeftbarToggle(realTimeValue);
  };

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu d-none">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }} key={idx}>
                {notf?.content}
              </label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header aftrlgn">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="leftbar_toggle d-md-block d-none">
                    <Button
                      variant="primary"
                      // onClick={() => setShowLeftbarToggle((s) => !s)}
                      onClick={() =>
                        dispatch(handleLeftSideBar(!showLeftSideBar))
                      }
                    >
                      <img src={ToggleIcon} />
                    </Button>
                  </div>
                  <div className="logo">
                    <a href="/home">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>

                  <div className="CasinoSports d-md-flex d-none">
                    <a href="/">
                      <img src={CasinoICon} alt="CasinoICon" />
                      Casino
                    </a>
                    <a href="/slots">
                      <img src={SlotsIcon} alt="CasinoICon" />
                      Slots
                    </a>
                    <a href="/exchange/Inplay">
                      <img src={SportsICon} alt="SportsICon" />
                      Exchange
                    </a>
                    {/* <a href="/sportsbook2">
                      <img src={SportsICon2} alt="SportsICon" />
                      Sportsbook 2
                    </a> */}
                  </div>

                  {/* <GamesSearchModal /> */}
                </div>

                <div className="d-flex align-items-center">
                  <Dropdown align="end" className="me-2 balanceDropdown">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      ₹ <span>{numberWithCommas(wallet?.cash || 0)}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="tel:{user?.mobileno}">
                        <b>{user?.mobileno}</b>
                      </Dropdown.Item>
                      <Dropdown.Item href="#">
                        <h6>Balance</h6>
                        <span>
                          INR {numberWithCommas(wallet?.balance || 0)}
                        </span>
                      </Dropdown.Item>
                      <div className="d-flex Breakdropmenu">
                        <Dropdown.Item
                          href="#"
                          onClick={() => setShowBetModal(true)}
                        >
                          <h6>Cash</h6>
                          <span>INR {numberWithCommas(wallet?.cash || 0)}</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => setShowBetModal(true)}
                        >
                          <h6>Exposure</h6>
                          <span>
                            INR {numberWithCommas(exposureAmount || 0)}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="/bonus">
                          <h6>Bonus</h6>
                          <span>INR {numberWithCommas(wallet?.coins)}</span>
                        </Dropdown.Item>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                  <div className="funds me-2">
                    <ul>
                      <li className="">
                        <a href="/gatewaylist" className="deposit">
                          Deposit
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="headerRight">
                  {/* <a href="/deposit" className="deposit primary">
                  Deposit
                </a> */}

                  <Dropdown align="end" className=" profiledropdown">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <img
                        src={Profile}
                        alt="my menu"
                        style={{ width: "22px" }}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/gatewaylist">
                        <h6>
                          {" "}
                          <img src={DepositIcon} alt="my menu" />{" "}
                          <span>Deposit</span>
                        </h6>
                      </Dropdown.Item>
                      <Dropdown.Item href="/withdraw">
                        <h6>
                          <img src={WithdrawIcon} alt="my menu" />{" "}
                          <span>Withdraw</span>
                        </h6>
                      </Dropdown.Item>
                      <Dropdown.Item href="/profile">
                        <h6>
                          <img src={ProfileIcon} alt="my menu" />{" "}
                          <span>Profile</span>
                        </h6>
                      </Dropdown.Item>
                      <Dropdown.Item href="/bonus">
                        <h6>
                          <img src={BonusIcon} alt="my menu" />{" "}
                          <span>Bonus</span>
                        </h6>
                      </Dropdown.Item>
                      <Dropdown.Item href="/mybets">
                        <h6>
                          <img src={MybetsIcon} alt="my menu" />{" "}
                          <span>My Bets</span>
                        </h6>
                      </Dropdown.Item>
                      <Dropdown.Item href="/cashier">
                        <img src={AccountStatememtIcon} alt="my menu" />{" "}
                        <span>Account Statememt</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="/referral">
                        {" "}
                        <h6>
                          {" "}
                          <img src={ReferralIcon} alt="my menu" />{" "}
                          <span>Referral</span>
                        </h6>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="/"
                        onClick={() => dispatch(logoutUser())}
                      >
                        <h6>
                          <img src={LogoutIcon} alt="my menu" />
                          <span>Logout</span>
                        </h6>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Button
                    variant="primary menu-btn"
                    onClick={() => setShow(true)}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom_head d-none">
          <Navbar expand="md" className="d-none d-md-block">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              // onClick={() => setShowToggle((s) => !s)}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Nav.Link href="/home" className="logoImg d-md-none">
                  <img src={appDetails?.LOGO_URL} alt="Logo" />
                </Nav.Link>
                <Nav.Link href="/spmsports/cricket">
                  <img src={Sports} />
                  Sports
                </Nav.Link>
                <Nav.Link href="/exchange/Cricket">
                  <img src={Exchange} />
                  Exchange
                </Nav.Link>
                <Nav.Link href="/betby" className="">
                  <img src={Sportsbook} />
                  Sportsbook
                </Nav.Link>
                <Nav.Link href="/sportsbook2" className="">
                  <img src={Sportsbook} />
                  Sportsbook 2
                </Nav.Link>
                <Nav.Link href={CasinoProviders["evolution"]?.href}>
                  <img src={Evolution} />
                  Evolution
                </Nav.Link>
                <Nav.Link href={CasinoProviders["ezugi"]?.href}>
                  <img src={Ezugi} />
                  Ezugi
                </Nav.Link>
                <Nav.Link href="/slots">
                  <img src={Slots} />
                  Slots
                </Nav.Link>
                <Nav.Link href="/kingmaker">
                  <img src={Kingmaker} />
                  Kingmaker
                </Nav.Link>
                <Nav.Link href="/casino/spribe/aviator">
                  <img src={Aviator} />
                  Aviaitor
                </Nav.Link>
                {/* <Nav.Link href="/casino/xpg">
                  <img src={XPGNew} />
                  XPG
                </Nav.Link>
                <Nav.Link href="/casino/vivo">
                  <img src={VivoNew} />
                  Vivo
                </Nav.Link>
                <Nav.Link href="/casino/qtech">
                  <img src={QtechNew} />
                  Qtech
                </Nav.Link>
                <Nav.Link href="/casino/worldcasino">
                  <img src={OnetouchNew} />
                  OneTouch
                </Nav.Link>
                <Nav.Link href="/casino/pragmaticplay">
                  <img src={ParagmaticplayNew} />
                  Pragmaticplay
                </Nav.Link>
                <Nav.Link href="/casino/onlyplay">
                  <img src={onlyplayNew} />
                  Onlyplay
                </Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>

        <Offcanvas show={show} onHide={() => setShow(false)} placement={"end"}>
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <img src={Profile} alt="profile" style={{ width: "24px" }} />
                </div>
                <div className="user-info">
                  <span>{user?.mstruserid}</span>
                  {/* <a href="tel:{user?.mobileno}">{user?.mobileno}</a> */}
                  <a>{user?.mobileno}</a>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="top_sec">
              <div className="row">
                <div className="col-6">
                  <div className="balance mb-1">Main Balance</div>
                </div>
                <div className="col-6">
                  <div className="balance-amount mb-1">
                    {numberWithCommas(wallet?.cash || 0)}
                  </div>
                </div>
                <div className="col-6">
                  <div className="balance mb-3">Bonus</div>
                </div>
                <div className="col-6">
                  <div className="balance-amount mb-3">
                    {numberWithCommas(wallet?.coins) || 0}
                  </div>
                </div>
                <div
                  className="col-6 "
                  onClick={() => {
                    navigate("/withdraw");
                  }}
                >
                  <Button variant="  w-100" className="btn-secondary">
                    Withdraw
                  </Button>
                </div>
                <div
                  className="col-6"
                  onClick={() => {
                    navigate("/gatewaylist");
                  }}
                >
                  <Button variant="primary w-100">Deposit</Button>
                </div>
                {/* <div className="col-4">
                <Button variant="primary w-100" onClick={handleShowModal}>
                  Exchange
                </Button>
                <ExchangeFundsFormPopUp
                  showexchangeModal={showexchangeModal}
                  handleCloseModal={handleCloseModal}
                />
              </div> */}
              </div>
            </div>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <img src={Profile} alt="profile" />
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  <img src={MyBets} alt="my bets" />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/bonus">
                  <img src={Bonus} alt="bonus" />
                  <span>Bonus</span>
                </a>
              </li>
              <li>
                <a href="/referral">
                  <img src={Referral} alt="referral" />

                  <span>Referral</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <img src={Statement} alt="account statement" />
                  <span>Account Statement</span>
                </a>
              </li>
              {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
              {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
              <li>
                <a href="/spmgames">
                  <img src={SPMGames} alt="SPM games" />
                  <span>SPM Games</span>
                </a>
              </li>
              <li>
                <a href="/kyc">
                  <VscVerifiedFilled />
                  <span>KYC</span>
                </a>
              </li>
              <li onClick={() => dispatch(logoutUser())}>
                <a href="/">
                  <img src={Logout} alt="logout" />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
            <ul className="social_links">
              <li>
                <a target="_blank" href={`${appDetails?.TELEGRAM}`}>
                  <TelegramIcon />
                </a>
              </li>
              {/* <li>
              <div
                onClick={() =>
                  window.open(https://wa.me/+917888846060, "_blank")
                }
              >
                <WhatsappIcon />
              </div>
            </li> */}
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <WhatsappIcon />
                </a>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Exposure amount</th>
                      <th scope="col">Match name</th>
                      <th scope="col">Ref match ID</th>
                      <th scope="col">Total bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Odds</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Selection type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </header>
    </>
  );
};

export default HeaderAfterLogin;
