import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
import Instagram from "../assets/images/Instagram.svg";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";
import PaymentLogo1 from "../assets/images/payments/Visa.webp";
import PaymentLogo2 from "../assets/images/payments/masterCard.webp";
import PaymentLogo3 from "../assets/images/payments/ApplePay.webp";
import PaymentLogo4 from "../assets/images/payments/Gpay.webp";
import PaymentLogo5 from "../assets/images/payments/Openbanking.webp";
import ProviderLogo1 from "../assets/images/providers/Evolution.jpg";
import ProviderLogo2 from "../assets/images/providers/Ezugi.jpg";
import ProviderLogo3 from "../assets/images/providers/Supernowa.jpg";
import ProviderLogo4 from "../assets/images/providers/XPG.jpg";
import ProviderLogo5 from "../assets/images/providers/Vivogaming.jpg";
import ProviderLogo7 from "../assets/images/providers/OneTouch.jpg";
import ProviderLogo8 from "../assets/images/providers/Pragmaticplay.jpg";
import ProviderLogo9 from "../assets/images/providers/Onlyplay.jpg";
import PaymentLogo6 from "../assets/images/payments/Bitcoin.webp";
import PaymentLogo7 from "../assets/images/payments/Intreact.webp";
import PaymentLogo8 from "../assets/images/payments/Blik.webp";
import PaymentLogo9 from "../assets/images/payments/plus18.webp";
import PaymentLogo10 from "../assets/images/payments/Fg.webp";
import Ezugi from "../assets/images/games-icon/headicon/ezugi.png";
import Menu from "../assets/images/games-icon/menu.webp";

import Evolution from "../assets/images/games-icon/headicon/evolution.png";
import HomeIcon from "../assets/images/games-icon/headicon/house.png";
import Gcb from "../assets/images/icons/gcb.png";
import { handleLeftSideBar } from "../redux/app/actions";

import Casinoicon from "../assets/images/icons/casino.webp";
import livecasino from "../assets/images/icons/Livecasino.webp";
import sports from "../assets/images/icons/AllSports.webp";
import sportsicon2 from "../assets/images/icons/customer-support.png";

const BeforeLoginFooter = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const { showLeftSideBar } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const socialMediaLinks = [
    {
      icon: Whatsapp,
      url: appDetails?.WHATS_APP_URL,
      target: "_blank",
    },
    {
      icon: Telegram,
      url: appDetails?.TELEGRAM,
      target: "_blank",
    },
    {
      icon: Instagram,
      url: appDetails?.INSTATGRAM,
      target: "_blank",
    },
    {
      icon: "/images/facebook-512.svg",
      url: appDetails?.FACEBOOK,
      target: "_blank",
    },
  ];

  const casinoLinks = [
    {
      title: "CASINO HOME",
      url: "",
    },
    {
      title: "LIVE CASINO",
      url: "",
    },
  ];

  const sportsLinks = [
    {
      title: "INPLAY",
      url: "",
    },
    {
      title: "SOCCER",
      url: "",
    },
    {
      title: "TENNIS",
      url: "",
    },
    {
      title: "CRICKET",
      url: "",
    },
    {
      title: "HORSE RACING",
      url: "",
    },
    {
      title: "GREYHOUND RACING",
      url: "",
    },
  ];

  const legalLinks = [
    {
      title: "CONTACT US",
      url: "/contactus",
    },
    {
      title: "PRIVACY POLICY",
      url: "/privacypolicy",
    },
    {
      title: "RESPONSIBLE GAMING",
      url: "/responsiblegame",
    },
  ];

  const aboutLinks = [
    {
      title: "FAIR PLAY",
      url: "/fairplay",
    },
    {
      title: "GAMES RULES",
      url: "/gamesrule",
    },
    {
      title: "TERMS & CONDITIONS",
      url: "/terms",
    },
    {
      title: "PROMOTION",
      url: "/",
    },
  ];

  const paymentLogos = [
    PaymentLogo1,
    PaymentLogo2,
    PaymentLogo3,
    PaymentLogo4,
    PaymentLogo5,
    PaymentLogo6,
    PaymentLogo7,
    PaymentLogo8,
    PaymentLogo9,
    PaymentLogo10,
  ];

  return (
    <footer className="footer">
      <div className="FoooterLinks">
        <div className="LinksSection">
          <div className="LinksFirstSection">
            <div className="LogoFooter">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
              <p> 2024 bigwin.com</p>
              <p>All Rights Reserved.</p>
            </div>
            <div>
              <ul>
                {socialMediaLinks.map((link, index) => (
                  <li key={index}>
                    <a
                      className="panel"
                      href={link.url !== "" ? link.url : "#"}
                      target={link.url !== "" ? link.target : ""}
                    >
                      <div className="img-box">
                        <img src={link.icon} alt="Icon" />
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="LinksSection">
          <h4>CASINO</h4>
          <ul>
            {casinoLinks.map((link, index) => (
              <li key={index}>
                <a href={link.url}>{link.title}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="LinksSection">
          <h4>SPORTS</h4>
          <ul>
            {sportsLinks.map((link, index) => (
              <li key={index}>
                <a href={link.url}>{link.title}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="LinksSection">
          <h4>LEGAL</h4>
          <ul>
            {legalLinks.map((link, index) => (
              <li key={index}>
                <a href={link.url}>{link.title}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="LinksSection">
          <h4>ABOUT</h4>
          <ul>
            {aboutLinks.map((link, index) => (
              <li key={index}>
                <a href={link.url}>{link.title}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="FooterContent">
        <p>
          Bigwin.com is operated by SIMBA N.V., a company incorporated under the
          laws of Curaçao with number 164834 and address Zuikertuintjeweg Z/N
          (Zuikertuin Tower), Willemstad, Curacao. SIMBA N.V. and by Sand Tech
          Services Ltd, a company registered in Cyprus with number 452264 and
          address 1 Avlonos Street, Maria House, 4th Floor 1075, Nicosia,
          Cyprus. SIMBA N.V. is licensed by the Curaçao Gaming Control Board to
          offer games of chance under license number OGL/2024/1788/1030 in
          accordance with the National Ordinance on Offshore Games of Hazard
          (Landsverordening buitengaatse hazardspelen, P.B. 1993, no. 63)
          (NOOGH).
        </p>
        <img src={Gcb} alt="" />
      </div>
      <div className="FooterPaymentSectiom">
        <div className="providers_logo payment_logo d-flex flex-wrap justify-content-center">
          {paymentLogos.map((logo, index) => (
            <a key={index} href="javascript:void(0)" className="logo_box">
              <img src={logo} alt="Provider Logo" />
            </a>
          ))}
        </div>
      </div>
      <div className="inner-content">
        <div className="container">
          <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
            <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
              <li>
                <a
                  href="#"
                  onClick={() => {
                    dispatch(handleLeftSideBar(!showLeftSideBar));
                  }}
                >
                  <img src={Menu} alt="sports" />
                  <span>Menu</span>
                </a>
              </li>

              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={Casinoicon} alt="evolution" />
                  <span>Casino</span>
                </a>
              </li>
              <li>
                <a className="active" href="/">
                  <img src={livecasino} alt="home" />
                  <span>Live Casino</span>
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={sports} alt="ezugi" />
                  <span>Exchange</span>
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                >
                  <img src={sportsicon2} alt="ezugi" />
                  <span>Support</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default BeforeLoginFooter;
