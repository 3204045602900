import React from "react";
import { APP_ROUTES_TYPE } from "./const";

const WithdrawMethodPreFormPage = React.lazy(() =>
  import("../views/pages/privatePages/withdrawNew/withdrawMethodPreFormPage")
);
const WithdrawMethodForm = React.lazy(() =>
  import("../views/pages/privatePages/withdrawNew/withdrawMethodForm")
);
const WithdrawMethods = React.lazy(() =>
  import("../views/pages/privatePages/withdrawNew/withdrawMethods")
);
const AtlasLobby = React.lazy(() =>
  import("../views/pages/privatePages/sportsBook/atlasLobby")
);

const Bkash = React.lazy(() =>
  import("../views/pages/privatePages/deposit/Bkash")
);
const Nagad = React.lazy(() =>
  import("../views/pages/privatePages/deposit/Nagad")
);
const Paytm = React.lazy(() =>
  import("../views/pages/privatePages/deposit/Paytm")
);

const PublicSportsBook = React.lazy(() =>
  import("../views/pages/privatePages/sportsBook")
);
const ContactUs = React.lazy(() =>
  import("../views/pages/commonPages/FooterPages/ContactUs")
);
const PrivacyPolicy = React.lazy(() =>
  import("../views/pages/commonPages/FooterPages/PrivacyPolicy")
);
const ResponsibleGame = React.lazy(() =>
  import("../views/pages/commonPages/FooterPages/ResponsibleGame")
);
const FairPlay = React.lazy(() =>
  import("../views/pages/commonPages/FooterPages/FairPlay")
);
const GamesRule = React.lazy(() =>
  import("../views/pages/commonPages/FooterPages/GamesRule")
);
const Terms = React.lazy(() =>
  import("../views/pages/commonPages/FooterPages/Terms")
);

// const BeforeContactUs = React.lazy(() =>
//   import("../views/pages/publicPages/FooterPages/BeforeContactUs")
// );
// const BeforeFairPlay = React.lazy(() =>
//   import("../views/pages/publicPages/FooterPages/BeforeFairPlay")
// );
// const BeforeGamesRule = React.lazy(() =>
//   import("../views/pages/publicPages/FooterPages/BeforeGamesRule")
// );
// const BeforePrivacyPolicy = React.lazy(() =>
//   import("../views/pages/publicPages/FooterPages/BeforePrivacyPolicy")
// );
// const BeforeResponsibleGame = React.lazy(() =>
//   import("../views/pages/publicPages/FooterPages/BeforeResponsibleGame")
// );
// const BeforeTerms = React.lazy(() =>
//   import("../views/pages/publicPages/FooterPages/BeforeTerms")
// );
const TestSportsBook = React.lazy(() =>
  import("../views/pages/privatePages/sportsBook/testSportsBook")
);
const Bonus = React.lazy(() => import("../views/pages/privatePages/bonus"));
const BonusCard = React.lazy(() =>
  import("../views/pages/privatePages/bonus/bonusCard")
);
const Referral = React.lazy(() =>
  import("../views/pages/privatePages/referral")
);
const GatewaysList = React.lazy(() =>
  import("../views/pages/privatePages/deposit/gatewaysList")
);
const DepositGateway = React.lazy(() =>
  import("../views/pages/privatePages/deposit/depositGateway")
);
const WithDrawGatewaysList = React.lazy(() =>
  import("../views/pages/privatePages/withdraw/withdrawGatewaysList")
);
const WithdrawGateway = React.lazy(() =>
  import("../views/pages/privatePages/withdraw/withdrawGateway")
);
const PlayerBankDetailsForm = React.lazy(() =>
  import("../views/pages/privatePages/playerBankDetails")
);
const CustomSports = React.lazy(() =>
  import("../views/pages/privatePages/customSports")
);
const CasinoProviderGames = React.lazy(() =>
  import("../containers/CasinoProviderGames")
);
const Kingmaker = React.lazy(() =>
  import("../views/pages/privatePages/Kingmaker")
);
const Slots = React.lazy(() => import("../views/pages/privatePages/Slots"));
const Aura = React.lazy(() => import("../views/pages/privatePages/aura"));

const Maintenance = React.lazy(() =>
  import("../views/pages/publicPages/Maintenance")
);
const ExchangeSPMSportsLobbyPage = React.lazy(() =>
  import("../views/pages/privatePages/exchange/ExchangeSPMSportsLobbyPage")
);
const Home = React.lazy(() => import("../views/pages/privatePages/home"));
const Games = React.lazy(() => import("../views/pages/privatePages/games"));
const DynamicCasinoPage = React.lazy(() =>
  import("../views/pages/privatePages/games/DynamicCasinoPage")
);
const Cashier = React.lazy(() => import("../views/pages/privatePages/cashier"));
const LandingPage = React.lazy(() =>
  import("../views/pages/publicPages/landingPage")
);
const Deposit = React.lazy(() => import("../views/pages/privatePages/deposit"));
const Withdraw = React.lazy(() =>
  import("../views/pages/privatePages/withdraw")
);
const Register = React.lazy(() =>
  import("../views/pages/publicPages/register")
);
const Login = React.lazy(() => import("../views/pages/publicPages/login"));
const MobileNumberVerify = React.lazy(() =>
  import("../views/pages/publicPages/register/mobileNumberVerify")
);
const MobileNumber = React.lazy(() =>
  import("../views/pages/publicPages/register/mobileNumber")
);
const Profile = React.lazy(() => import("../views/pages/privatePages/profile"));
const Betby = React.lazy(() => import("../views/pages/privatePages/betby"));
const ExchangeLobbyPage = React.lazy(() =>
  import("../views/pages/privatePages/exchange/ExchangeLobbyPage")
);
const MyBets = React.lazy(() => import("../views/pages/privatePages/myBets"));
const WithdrawList = React.lazy(() =>
  import("../views/pages/privatePages/withdraw/WithdrawList")
);
const ForgotPassword = React.lazy(() =>
  import("../views/pages/publicPages/forgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("../views/pages/publicPages/forgotPassword/resetPassword")
);
const JetFairLobbyPage = React.lazy(() =>
  import("../views/pages/privatePages/exchange/jetFairLobby")
);

const WithdrawGateways = React.lazy(() =>
  import("../views/pages/privatePages/withdrawNew/Gateways")
);
const DepositGateways = React.lazy(() =>
  import("../views/pages/privatePages/deposit/gateways")
);
const KycModule = React.lazy(() => import("../views/pages/privatePages/kyc"));
const CasinoGames = React.lazy(() =>
  import("../views/pages/commonPages/casinoGames")
);

export const APP_ROUTES = [
  {
    id: 0,
    path: "/",
    component: LandingPage,
    routeType: APP_ROUTES_TYPE.PUBLIC,
    pageName: "Landing Page",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 1,
    path: "/kyc",
    component: KycModule,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "KYC",
  },
  {
    id: 2,
    path: "/maintenance",
    component: Maintenance,
    routeType: APP_ROUTES_TYPE.COMMON,
    pageName: "Maintenance",
    props: {
      layout: {
        header: false,
        footer: false,
      },
    },
  },
  {
    id: 3,
    path: "/home",
    component: Home,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Home",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 4,
    path: "/sportsbook2",
    component: AtlasLobby,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Atlas Lobby",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 5,
    path: "/games-all",
    component: Games,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Games",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 6,
    path: "/kingmaker",
    component: Kingmaker,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "King maker",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 7,
    path: "/slots",
    component: Slots,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Slots",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 8,
    path: "/aura-games",
    component: Aura,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Aura Games",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 9,
    path: "/spmgames",
    component: CustomSports,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "SPM Games",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 10,
    path: "/casino/:casinoslug",
    component: DynamicCasinoPage,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Casino",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 11,
    path: "/casino/:casinoslug/:gameslug",
    component: DynamicCasinoPage,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Casino",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 12,
    path: "/cashier",
    component: Cashier,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Account statement",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 13,
    path: "/withdrawList",
    component: WithdrawList,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Withdrawlist",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 14,
    path: "/mybets",
    component: MyBets,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "My bets",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 15,
    path: "/deposit/:gateway",
    component: DepositGateway,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Gateways",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 16,
    path: "/gatewaylist",
    component: GatewaysList,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Gateways list",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  // {
  //   id: 17,
  //   path: "/withdrawGateways",
  //   component: WithDrawGatewaysList,
  //   routeType: APP_ROUTES_TYPE.PROTECTED,
  //   pageName: "Withdraw gateway list",
  // },
  // {
  //   id: 18,
  //   path: "/withdrawGateway/:gateway",
  //   component: WithdrawGateway,
  //   routeType: APP_ROUTES_TYPE.PROTECTED,
  //   pageName: "Withdraw gateway",
  // },
  {
    id: 21,
    path: "/withdraw",
    component: Withdraw,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Withdraw",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  // {
  //   id: 22,
  //   path: "/betby",
  //   component: Betby,
  //   routeType: APP_ROUTES_TYPE.PROTECTED,
  //   pageName: "Betby",
  //   props: {
  //     layout: {
  //       header: true,
  //       footer: false,
  //     },
  //     toggleDefault: false,
  //   },
  // },
  {
    id: 23,
    path: "/register",
    component: Register,
    routeType: APP_ROUTES_TYPE.PUBLIC,
    pageName: "Register",
    props: {
      layout: {
        header: false,
        footer: false,
      },
    },
  },
  {
    id: 24,
    path: "/sign-in",
    component: Login,
    routeType: APP_ROUTES_TYPE.PUBLIC,
    pageName: "Login",
    props: {
      layout: {
        header: false,
        footer: false,
      },
    },
  },
  {
    id: 25,
    path: "/verify-number",
    component: MobileNumberVerify,
    routeType: APP_ROUTES_TYPE.PUBLIC,
    pageName: "verify-number",
    props: {
      layout: {
        header: false,
        footer: false,
      },
    },
  },
  {
    id: 26,
    path: "/mobile-number",
    component: MobileNumber,
    routeType: APP_ROUTES_TYPE.PUBLIC,
    pageName: "Mobile number",
    props: {
      layout: {
        header: false,
        footer: false,
      },
    },
  },
  {
    id: 27,
    path: "/profile",
    component: Profile,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Profile",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 28,
    path: "/spmsports/:category",
    component: ExchangeSPMSportsLobbyPage,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "SPM exchange",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 29,
    path: "/exchange/:gameId",
    component: ExchangeLobbyPage,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Exchange lobby",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 30,
    path: "/contactus",
    component: ContactUs,
    routeType: APP_ROUTES_TYPE.COMMON,
    pageName: "Contact us",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 31,
    path: "/privacypolicy",
    component: PrivacyPolicy,
    routeType: APP_ROUTES_TYPE.COMMON,
    pageName: "Privacy Policy",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 32,
    path: "/responsiblegame",
    component: ResponsibleGame,
    routeType: APP_ROUTES_TYPE.COMMON,
    pageName: "Responsible Gaming",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 33,
    path: "/fairplay",
    component: FairPlay,
    routeType: APP_ROUTES_TYPE.COMMON,
    pageName: "Fairplay",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 34,
    path: "/gamesrule",
    component: GamesRule,
    routeType: APP_ROUTES_TYPE.COMMON,
    pageName: "Game Rules",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 35,
    path: "/terms",
    component: Terms,
    routeType: APP_ROUTES_TYPE.COMMON,
    pageName: "Terms",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 36,
    path: "/bonus",
    component: Bonus,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Bonus",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 37,
    path: "/referral",
    component: Referral,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Referral",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 38,
    path: "/bonus/:id",
    component: BonusCard,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Bonus page",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  // {
  //   id: 39,
  //   path: "/contactuss",
  //   component: BeforeContactUs,
  //   routeType: APP_ROUTES_TYPE.PUBLIC,
  //   pageName: "Contact us",
  // },
  // {
  //   id: 40,
  //   path: "/privacypolicys",
  //   component: BeforePrivacyPolicy,
  //   routeType: APP_ROUTES_TYPE.PUBLIC,
  //   pageName: "Privacy Policy",
  // },
  // {
  //   id: 41,
  //   path: "/fairplays",
  //   component: BeforeFairPlay,
  //   routeType: APP_ROUTES_TYPE.PUBLIC,
  //   pageName: "Fairplays",
  // },
  // {
  //   id: 42,
  //   path: "/responsiblegames",
  //   component: BeforeResponsibleGame,
  //   routeType: APP_ROUTES_TYPE.PUBLIC,
  //   pageName: "Responsible Games",
  // },
  // {
  //   id: 43,
  //   path: "/gamesrules",
  //   component: BeforeGamesRule,
  //   routeType: APP_ROUTES_TYPE.PUBLIC,
  //   pageName: "Game Rules",
  // },
  // {
  //   id: 44,
  //   path: "/termss",
  //   component: BeforeTerms,
  //   routeType: APP_ROUTES_TYPE.PUBLIC,
  //   pageName: "Terms",
  // },
  {
    id: 45,
    path: "/sportsbooktest",
    component: TestSportsBook,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Sportsbook Test",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 46,
    path: "/forgot-password",
    component: ForgotPassword,
    routeType: APP_ROUTES_TYPE.PUBLIC,
    pageName: "Forgot password",
    props: { layout: false },
  },
  {
    id: 47,
    path: "/reset-password",
    component: ResetPassword,
    routeType: APP_ROUTES_TYPE.PUBLIC,
    pageName: "Reset Password",
    props: { layout: false },
  },
  {
    id: 48,
    path: "/bank-details",
    component: PlayerBankDetailsForm,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Bank Details",
  },
  {
    id: 49,
    path: "/jetfair",
    component: JetFairLobbyPage,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Jetfair",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 50,
    path: "/:providerCode",
    component: CasinoProviderGames,
    routeType: APP_ROUTES_TYPE.COMMON,
    pageName: "providerCode",
  },
  // {
  //   id: 51,
  //   path: "/withdrawnew",
  //   component: WithdrawNew,
  //   routeType: APP_ROUTES_TYPE.PROTECTED,
  //   pageName: "providerCode",
  // },
  {
    id: 52,
    path: "/bkash",
    component: Bkash,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Bkash",
  },
  {
    id: 53,
    path: "/paytm",
    component: Paytm,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Paytm",
  },
  // {
  //   id: 54,
  //   path: "/bkashwithdraw",
  //   component: BkashWitdraw,
  //   routeType: APP_ROUTES_TYPE.PROTECTED,
  //   pageName: "Bkash Withdraw",
  // },
  // {
  //   id: 55,
  //   path: "/paytmwithdraw",
  //   component: PaytmWithdraw,
  //   routeType: APP_ROUTES_TYPE.PROTECTED,
  //   pageName: "Paytm Withdraw",
  // },
  {
    id: 56,
    path: "/nagad",
    component: Nagad,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Nagad",
  },
  // {
  //   id: 57,
  //   path: "/nagadwithdraw",
  //   component: NagadWithdraw,
  //   routeType: APP_ROUTES_TYPE.PROTECTED,
  //   pageName: "Nagad Withdraw",
  // },
  {
    id: 58,
    path: "/deposit/:mode",
    component: DepositGateways,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Deposit gateways",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 59,
    path: "/withdraw/:mode",
    component: WithdrawGateways,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Withdraw gateways",
  },
  // {
  //   id: 60,
  //   path: "/TronConfirm",
  //   component: TronConfirm,
  //   routeType: APP_ROUTES_TYPE.PROTECTED,
  //   pageName: "Tron confirm",
  // },
  // {
  //   id: 61,
  //   path: "/TronConfirmWithdraw",
  //   component: TronConfirmWithdraw,
  //   routeType: APP_ROUTES_TYPE.PROTECTED,
  //   pageName: "Tron confirm withdraw",
  // },
  // {
  //   id: 62,
  //   path: "/paytmwithdraw",
  //   component: PaytmWithdraw,
  //   routeType: APP_ROUTES_TYPE.PROTECTED,
  //   pageName: "Deposit gateways",
  // },
  {
    id: 63,
    path: "/withdrawnew",
    component: WithdrawMethods,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Withdraw Test",
  },
  {
    id: 64,
    path: "/withdrawnewForm",
    component: WithdrawMethodForm,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Withdraw Test",
    props: {
      layout: {
        header: true,
        footer: false,
      },
    },
  },
  {
    id: 65,
    path: "/pre-withdraw-page/:method",
    component: WithdrawMethodPreFormPage,
    routeType: APP_ROUTES_TYPE.PROTECTED,
    pageName: "Withdraw Test",
  },
  {
    id: 66,
    path: "/casino",
    component: CasinoGames,
    routeType: APP_ROUTES_TYPE.COMMON,
    pageName: "Casino Games",
  },
];
