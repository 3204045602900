import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Nav, Navbar } from "react-bootstrap";
import Sports from "../assets/images/games-icon/headicon/sports.png";
import Exchange from "../assets/images/games-icon/headicon/exchange.png";
import Sportsbook from "../assets/images/games-icon/headicon/sportsbook.png";
import Evolution from "../assets/images/games-icon/headicon/evolution.png";
import Ezugi from "../assets/images/games-icon/headicon/ezugi.png";
import Slots from "../assets/images/games-icon/headicon/slots.png";
import Kingmaker from "../assets/images/games-icon/headicon/kingmaker.png";
import Aviator from "../assets/images/games-icon/Aviator.png";

import Menu from "../assets/images/icons/toggle-icon.png";
import WhatsappIconNew from "../assets/images/social/whatsapp.png";
import TelegramIconNew from "../assets/images/social/telegram.png";
import GamesSearchModal from "../views/components/GamesSearchModal";
import ModalWrapper from "./ModalWrapper";
import { APP_CONST } from "../config/const";

import CasinoICon from "../assets/images/icons/spades.png";
import SportsICon from "../assets/images/icons/football.png";
import SportsICon2 from "../assets/images/icons/trophy.webp";
import { handleLeftSideBar } from "../redux/app/actions";
import SlotsIcon from "../assets/images/icons/slot-machine.png";

const Header = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const htmlElement = document.documentElement;
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState("login");
  const dispatch = useDispatch();

  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };
  const { showLeftSideBar } = useSelector((state) => state.app);

  useEffect(() => {
    if (showLeftSideBar) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbat-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftSideBar]);
  const handleToggleClick = () => {
    const realTimeValue = !showLeftbarToggle;
    if (realTimeValue) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbat-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbat-opened");
    }
    setShowLeftbarToggle(realTimeValue);
  };

  return (
    <header className="header beforeheader">
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="leftbar_toggle d-md-block d-none">
                  <Button
                    variant="primary"
                    onClick={() =>
                      dispatch(handleLeftSideBar(!showLeftSideBar))
                    }
                  >
                    <img src={Menu} />
                  </Button>
                </div>
                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>
                <div className="CasinoSports d-md-flex d-none">
                  <a href="/casino">
                    <img src={CasinoICon} alt="CasinoICon" />
                    Casino
                  </a>
                  <a href="/slots">
                    <img src={SlotsIcon} alt="CasinoICon" />
                    Slots
                  </a>
                  <a href="">
                    <img src={SportsICon} alt="SportsICon" />
                    Exchange
                  </a>
                  {/* <a href="/sportsbook2">
                    <img src={SportsICon2} alt="SportsICon" />
                    Sportsbook2
                  </a> */}
                </div>

                {/* <GamesSearchModal /> */}
              </div>

              <div className="headerRight">
                <ul className="social d-none">
                  <li>
                    <a
                      href={
                        appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                      }
                      target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                    >
                      {/* <TelegramIcon /> */}
                      <img src={TelegramIconNew} />
                    </a>
                  </li>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "#"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      {/* <WhatsappIcon /> */}
                      <img src={WhatsappIconNew} />
                    </a>
                  </li>
                </ul>
                <Button
                  variant="primary"
                  onClick={() => {
                    handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                  }}
                  className="button-primary btn_signin"
                >
                  Log in
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                  }}
                  className="button-primary btn_signup btn-secondary ms-2"
                >
                  SIGN UP
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div xs={12} className="bottom_head d-none">
        <Navbar expand="md">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/sign-in">
                  <img src={Sports} />
                  Sports
                </Nav.Link>
                <Nav.Link href="/sign-in">
                  <img src={Exchange} />
                  Exchange
                </Nav.Link>
                <Nav.Link href="/sign-in" className="">
                  <img src={Sportsbook} />
                  Sportsbook
                </Nav.Link>
                <Nav.Link href="/sign-in">
                  <img src={Evolution} />
                  Evolution
                </Nav.Link>
                <Nav.Link href="/sign-in">
                  <img src={Ezugi} />
                  Ezugi
                </Nav.Link>
                <Nav.Link href="/sign-in">
                  <img src={Slots} />
                  Slots
                </Nav.Link>
                <Nav.Link href="/sign-in">
                  <img src={Kingmaker} />
                  Kingmaker
                </Nav.Link>
                <Nav.Link href="/sign-in">
                  <img src={Aviator} />
                  Aviaitor
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          handleClose={setShowAuthModals}
        />
      )}
    </header>
  );
};

export default Header;
